<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">购保记录</span>
      <le-export-btn v-if="false" :page-param="pageParam"></le-export-btn>
    </div>

    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input
          v-model="pageParam.params.orderId"
          type="number"
          label="订单编号"
          :maxlength="50"
        />
        <le-input
          v-model="pageParam.params.mobile"
          type="number"
          label="手机号码"
          :maxlength="11"
        />
        <le-select-local-search
          v-model="pageParam.params.secureServiceStatus"
          label="支付状态"
          :options="options"
        />
        <le-date-range
          label="下单时间"
          :min-date.sync="pageParam.params.startTime"
          :max-date.sync="pageParam.params.endTime"
        />
      </le-search-form>

      <le-pagview id="table_box" :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          :page-param="pageParam"
          style="width: 100%"
        >
          <el-table-column label="序号" align="center" type="index" width="50" />
          <el-table-column label="订单编号">
            <template slot-scope="{ row }">
              <el-link type="primary" @click.prevent="onViewDeviceDetail(row)">
                {{ row.orderId }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="手机号码" prop="mobile" />
          <el-table-column label="保费金额" prop="secureServiceAmount" />
          <el-table-column label="支付状态" prop="secureServiceStatusText" />
          <el-table-column label="下单时间" prop="createTime" />
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
const INSURE_OPTIONS = [
  { label: "已购买", value: 1 },
  { label: "已退款", value: 3 },
];

const INSURE_STATUS_MAP = {
  1: "已购买",
  2: "未购买",
  3: "已退款",
};

export default {
  name: "InsureRecord",
  data() {
    return {
      pageParam: {
        url: this.$Config.apiUrl.getInsureOrderList,
        method: "post",
        params: {
          orderId: "",
          mobile: "",
          startTime: "",
          endTime: "",
          secureServiceStatus: "",
        },
        freshCtrl: 1,
      },
      tableData: [],
    };
  },
  computed: {
    options() {
      return INSURE_OPTIONS;
    },
  },
  methods: {
    handlerRest() {
      this.pageParam.params = {
        orderId: "",
        mobile: "",
        startTime: "",
        endTime: "",
        secureServiceStatus: "",
      };
      this.pageParam.freshCtrl++;
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    setTableData(data) {
      this.tableData = (data || []).map((item) => {
        return {
          ...item,
          secureServiceAmount: ((item.secureServiceAmount || 0) / 100).toFixed(2),
          secureServiceStatusText: INSURE_STATUS_MAP[item.secureServiceStatus] || "--",
        };
      });
    },
    onViewDeviceDetail(row) {
      if (!row.orderId) {
        return this.$message.warning("订单出错，请联系管理员");
      }
      this.$router.push({
        path: "/order/order-detail",
        query: {
          orderId: row.orderId,
        },
      });
    },
  },
};
</script>
